/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css?family=Muli');

* {
  font-family: 'Muli', sans-serif !important;
}

ion-menu {
  --width: 66% !important;
}

h2.alert-title.sc-ion-alert-md, h2.sc-ion-alert-ios {
  color: #6c6c6c !important;
}

div.alert-message {
  color: #6c6c6c !important;
}

div.alert-message.sc-ion-alert-md {
  text-align: center;
  padding: 0px 30px 16px 30px;
}

div.alert-button-group.sc-ion-alert-md {
  justify-content: center !important;
}

button.alert-button.sc-ion-alert-md {
  text-transform: none;
  border: 1px solid rgba(119, 119, 119, 0.459);
  color: #008f96;
  width: 75%;
  margin-top: 0 auto;
  padding: 3px;
  margin-bottom: 10px;
  box-shadow: 0 4px 2px -2px gray;

  span {
    color: #008f96;
    justify-content: center;
  }
}

ion-button.logout-button {
  width: 75%;
  border: 1px solid rgba(216, 213, 213, 0.87);
  font-size: 16px;
  border-radius: 0;
  background: transparent;
  text-transform: none;
  margin: 15px 0 40px 0;
  box-shadow: 0 4px 2px -2px #8080803b;
  --color: #008f95;
  --background: #fff;
  height: 37px;
}

ion-label.name-user {
  --color: rgba(116, 116, 116, 0.76) !important;
}

ion-menu-button {
  color: #000000bf;
}

.alert-button.sc-ion-alert-ios {
  color: #008f96;
}

.sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: #62606099;
}

[aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: #008f96;
}

[aria-checked=true].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
  border-color: #008f96;
}

ion-button, button:active {
  background-color: transparent;
}

ion-button, button.selected {
  --ion-item-background: transparent;
}

.infinite-loading {
  margin-top: 10px;
  margin-bottom: 15px;
}

ion-button {
  --background-activated: transparent;
}

ion-valid {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset;
  box-shadow: 0 0 0 30px white inset;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: rgba(116, 116, 116, 0.76);
}

ion-refresher ion-refresher-content {
  background-color: #fff;
  .refresher-pulling .refresher-pulling-icon ion-icon {
    color: gray !important;
    background-color: #fff;
  }
}

ion-refresher ion-refresher-content .refresher-refreshing .refresher-refreshing-icon ion-spinner.spinner-dots {
  color: gray !important;
  background-color: #fff;
}

ion-avatar {
  width: 64px !important;
  height: 64px !important;
}

ion-search.sc-ion-searchbar-md-h.sc-ion-searchbar-md-s.searchbar-left-aligned {
  div.searchbar-input-container {
    ion-icon.searchbar-search-icon {
      color: red !important;
    }
  }
}

.budget-info-modal {
  .modal-wrapper {
    width: 340px !important;
    height: 430px !important;
    min-height: 430px !important;
    position: absolute;
    display: block;
  }
  ion-toolbar {
    .budget-label {
      margin: 0 12px
    }
    ion-buttons {
      position: relative;
      top: -10px;
      left: 14px;
      ion-button {
        zoom: .9;
        ion-icon {
          color: rgba(216, 213, 213, 0.87);
          stroke-width: 47px;
        }
      }
    }
  }
}

app-budget-info-modal {

  ion-content {
    // overwrite inline styles
    --overflow: hidden;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

//ion-datetime
button.picker-button.ion-activatable {
  color: #008f95 !important;
}

button.picker-opt.picker-opt-selected {
  color: #008f95 !important;
}

//popover elipsis (...) vertical
.sc-ion-popover-md-h, .sc-ion-popover-ios-h {
  --backdrop-opacity: 0;
}

div.popover-content.sc-ion-popover-ios, div.popover-content.sc-ion-popover-md {
  border-radius: 0px;
  overflow: hidden;
  max-width: 200px;
  box-shadow: 0px 0px 2px black;
  app-popover {
    border-radius: 0px;
    overflow: hidden;
    ion-item {
      height: 32px;
    }

    ion-label {
      font-size: 12px !important;
      margin: 0;
      height: 21px;
    }
  }
}

ion-select.form-select::part(icon) {
  margin-left: 40px !important;
}

//Dropdown ion-select interface=popover modal descuento
ion-select.popover-interface::part(icon) {
  display: none !important;
}

.my-custom-interface .select-interface-option {
  color: gray;
  margin: 0 auto;
  --background-activated: #1fc8d1 !important;
  --color-activated: white !important;
  --background-activated-opacity: 0.45;
  padding: 0px !important;
  margin-left: -20px;
  font-size: 15px;
}

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover, .sc-ion-select-popover-h ion-label.sc-ion-select-popover {
  margin-left: 8px;
  overflow: hidden;
  white-space: normal;
  padding: 6px;
}

ion-radio.radio-checked.hydrated {
  display: none;
}

div.item-native {
  --color: #fff !important;
  --background-color-active: #90ecf1 !important;
}

//Modal de informacion de presupuesto
app-budget-info-modal.ion-page {
  border: 1px solid gray;
}

/* chrome, safari */
ion-content::part(scroll)::-webkit-scrollbar {
  scrollbar-width: 2px;
}

/* firefox */
ion-content::part(scroll) {
  scrollbar-width: 2px;
}

input.searchbar-input.sc-ion-searchbar-md, button.searchbar-clear-button.sc-ion-searchbar-md {
  color: rgb(121, 121, 121);
}

ion-spinner.spinner-crescent {
  color: #008f95 !important;
  background-color: #fff;
}

ion-loading {
  .loading-content.sc-ion-loading-ios {
    color: gray;
  }
}

//searchbar icon in client-budget
ion-searchbar.client-budget-sb {
  ion-icon.searchbar-search-icon.sc-ion-searchbar-md.md.hydrated {
    right: 16px !important;
    left: auto !important;
  }

  ion-icon.searchbar-clear-icon.sc-ion-searchbar-md.md.hydrated {
    display: none;
  }
  input.searchbar-input.sc-ion-searchbar-md {
    padding-left: 15px;
  }
}

textarea {
  height: 200px;
}

ion-toolbar {
  box-shadow: 0 3px 0 white !important;
}

.ionic-selectable-value {
  margin-left: 18px;
}

.ionic-selectable-value-item {
  color: #62606099 !important;
}

.ionic-selectable-item {
  color: #62606099 !important;

  ion-selectable {
    .icon-md-primary {
      color: #008f95;
    }
  }
  .ionic-selectable-icon {
    color: #62606099 !important;
  }
  .ionic-selectable-modal {
    font-family: 'Muli', sans-serif !important;
  }
}

ion-modal.ios {
  //top: 34px
}

// cssClass in modalCtrl
.modal-wrapper.sc-ion-modal-ios {
  width: 100%;
  height: 100%;
}

.alert-radio-label.sc-ion-alert-ios {
  height: 50px;
  overflow: hidden;
  white-space: normal;
}

.alert-tappable.sc-ion-alert-ios {
  height: 50px;
}

.alert-wrapper.sc-ion-alert-ios {
  width: 100%;
}
